import React from 'react'

import '../styles/pages/404.scss'
import Layout from '../components/Layout/Layout'
import {Container, Row, Col} from 'react-bootstrap'
import img404 from '../../static/images/404.png'
import Button from '../components/Button/Button'
import Seo from '../components/Seo/Seo'

export default () => {
    return (
        <Layout>
            <Seo title='404 - Страница не найдена'/>
            <Container className='content' fluid="xl">
               
                <Row>
                    <Col>
                    <div className="p404">
                        <img src={img404} alt="Страница не найдена"/>
                        <h1>Данная страница не найдена</h1>
                        <p>Приносим свои извинения и предлагаем вернуться на сайт</p>
                        <Button 
                            as='link' 
                            text='Вернуться на сайт'
                            linkTo = ''

                        />
                    </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}